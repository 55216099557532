@import 'src/styles/vars';

.admin {
    &:before {
        position: fixed;
        box-sizing: border-box;
        top: 0;
        left: 0;
        content: 'admin area';
        background: $default-warning-color;
        color: white;
        text-align: left;
        font-family: monospace;
        font-size: 20px;
        height: 100%;
        width: 1.5rem;
        writing-mode: vertical-lr;
        text-orientation: upright;
    }
}

.container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    height: 100%;
    max-width: 80%;
}

.fullscreen {
    width: 100%;
    height: calc(100svh - 8px);
    height: calc(100vh - 8px);
    max-height: 100vh;
    max-height: 100svh;
    justify-content: space-between;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0;
}

.title {
    display: grid;
    justify-content: flex-start;
    grid-template-areas:
        'title title title navigateTo'
        'description';
    grid-template-columns: repeat(auto-fit, minmax(50px, min-content));
    grid-template-rows: min-content min-content;
    grid-column-gap: $default-gap-small;
    white-space: nowrap;
    align-items: baseline;

    //@include background_cross($default-background-pattern-color, $default-background-weave-color);
}

.blurTitle {
    backdrop-filter: blur(10px);
    border-radius: 0 0 $default-border-radius $default-border-radius;
}

.fixedBar {
    position: fixed;
    display: grid;
    align-items: baseline;
    top: 0;
    right: 0;
    padding: $default-padding;
    grid-auto-columns: max-content;
    grid-template-areas: 'a a a';
    grid-gap: $default-margin-small;
}

.descriptionLine {
    grid-area: description;
    grid-column: span 3;
}

.noBackground {
    background: transparent;
}

.ticker {
    display: flex;
    align-items: baseline;
}

.navigateTo {
    display: grid;
    grid-template-columns: min-content;
    align-items: flex-start;
}

.adminConnectionStatusContainer {
}

.adminConnectionStatus.connected {
    stroke: green !important;
}

.adminConnectionStatus.error {
    color: darkred !important;
}

.adminConnectionStatus.disconnected {
    color: darkred !important;
}

.adminConnectionStatus.not-initiated {
    color: darkgray !important;
}
