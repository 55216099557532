@import 'src/styles/vars';

.container {
    display: grid;
}

.userIdentifier {
    text-shadow: 1px 1px 2px white;
    //margin-right: $default-margin;
    visibility: hidden;
}

.authButton {
    width: 150px;
}

.container:hover > .userIdentifier {
    visibility: visible;
}

.alignRight {
    justify-self: end;
}
