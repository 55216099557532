@import 'src/styles/vars';

.container {
    display: flex;
    flex-direction: row;
}
.linkText {
    //border-bottom: solid 1px $default-accent-color;
    //padding: 0 1px 3px 1px;
    color: $default-accent-color;
    cursor: pointer;
    white-space: nowrap;
    width: min-content;
}

.lineDecorated {
    text-decoration-line: underline;
    text-decoration-color: currentColor;
}

.gigantic {
    font-size: $default-font-gigantic;
    font-weight: lighter;
}

.title {
    font-size: $default-font-h2;
}

.button {
    font-size: $default-font-h3;
}

.text {
    font-size: $default-font-text;
}

.small {
    font-size: $default-font-small;
    border-bottom-width: 1px;
}

.success {
    color: map-get($theme-colors, 'pallet-color-5');
    border-color: map-get($theme-colors, 'pallet-color-5');
}

.warning {
    color: $default-warning-color;
    border-color: $default-warning-color;
}

.accent {
    color: map-get($theme-colors, 'pallet-color-0');
    border-color: map-get($theme-colors, 'pallet-color-0');
}

.white {
    color: white;
    text-shadow: 1px 1px 2px gray;
}

.default {
}

.onDarkBackground.white {
    border-color: map-get($theme-colors, 'pallet-color-5');
}

.onDarkBackground:hover {
    border-color: white;
}

.onLightBackground:hover {
    border-color: map-get($theme-colors, 'pallet-color-1');
}

.disabled {
    color: $default-disabled-color;
    border-color: $default-disabled-color;
}

.metricTitle {
    font-weight: bold;
}

.noMargin {
    margin: 0;
}
